import VLoaderDialog from "@component-library/dialogs/VLoaderDialog.vue"
import { reactive, type App, type Component } from "vue"
import { createVueApp } from "~/features/vue"
import { useLocalize } from "./localization"
import type { Icon } from "@icons/index"

declare global {
  interface Window {
    _vLoaderInternals: {
      _app?: App<Element>
      _state: {
        message: string | undefined
        icon: Icon
        loading: boolean
        opaque: boolean
      }

      start (options?: { message?: string, icon?: Icon, opaque?: boolean }): void
      stop (): void

      set message(message: string)
      set icon(icon: Icon)
      set opaque(opaque: boolean)
    }
  }
}

window._vLoaderInternals ??= {
  _state: reactive({
    message: undefined,
    icon: 'Outline/avvoka',
    opaque: true,
    loading: false
  }),
  stop () {
    this._state.loading = false
  },
  start (options = {}) {
    this._state.message = options.message ?? useLocalize('component-library.layouts')('default_page_loader_message')
    this._state.icon = options.icon ?? 'Outline/avvoka'
    this._state.opaque = options.opaque ?? true
    this._state.loading = true
  },
  set message (message: string) {
    this._state.message = message
  },
  set icon (icon: Icon) {
    this._state.icon = icon
  },
  set opaque (opaque: boolean) {
    this._state.opaque = opaque
  }
}

window._vLoaderInternals._app ??= (() => {
  const containerElement = document.createElement('div')
  containerElement.id = 'v-loader-container'

  // TODO: Temporary workaround for weirdness in the app. Remove when not necessary
  containerElement.setAttribute('class', 'relative z-[1001]')

  document.body.appendChild(containerElement)

  const element = document.createElement('div')

  const instance = createVueApp(VLoaderDialog as Component)
  instance.provide('V:VLoaderDialog:State', window._vLoaderInternals._state)
  instance.mount(element)

  containerElement.appendChild(element)

  return instance
})()

export function useLoader () {
  return window._vLoaderInternals
}